import React, { useContext } from "react"
import Section from "../components/section"
import { WPContext } from "../contexts/wpContext"
import { Helmet } from "react-helmet"
import favicon64 from "../images/favicon64.png"
import favicon32 from "../images/favicon32.png"
import favicon16 from "../images/favicon16.png"
import social from "../images/social.png"

const Skule = () => {
  const { skuleArticles } = useContext(WPContext)
  return (
    <>
      <Helmet
        link={[
          {
            rel: "icon",
            type: "image/png",
            sizes: "16x16",
            href: `${favicon16}`,
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "32x32",
            href: `${favicon32}`,
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "64x64",
            href: `${favicon64}`,
          },
        ]}
      >
        <title>Skule™ - The Cannon</title>
        <meta property="og:title" content={`The Cannon`} />
        <meta
          property="og:description"
          content="The Cannon is the official (serious) newspaper of the University of Toronto Engineering Society. Established in 1978, it serves the undergraduate students of the Faculty of Applied Science and Engineering."
        />
        <meta property="og:image" content={social} />
        <meta property="og:image:url" content={social} />
      </Helmet>
      <Section
        data={skuleArticles}
        sectionName="SKULE™"
        sectionDescription="Keep up to date with news, events, and topics relating to Skule™. Learn about the students who make an impact, F!rosh Week, and discussions about engineering disciplines!"
      />
    </>
  )
}

export default Skule
